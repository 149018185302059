<template>
  <nav class="carousel-navigation">
    <p v-if="isCounterVisible" class="carousel-navigation__counter">
      {{ slideCounter }}
    </p>
    <div
      class="carousel-navigation__button carousel-navigation__button--prev"
      :class="{ 'carousel-navigation__button--disabled': isFirstSlideActive }"
      @click="setPreviousSlide()"
    />
    <div
      class="carousel-navigation__button carousel-navigation__button--next"
      :class="{ 'carousel-navigation__button--disabled': isLastSlideActive }"
      @click="setNextSlide()"
    />
  </nav>
</template>

<script lang="ts" setup>
interface Props {
  isFirstSlideActive?: boolean
  isLastSlideActive?: boolean
  countSlides?: number
  currentSlide?: number
  isCounterVisible?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isFirstSlideActive: false,
  isLastSlideActive: false,
  countSlides: 0,
  currentSlide: 0,
  showCounter: false,
})
const emit = defineEmits<{
  (e: 'setNextSlide'): void
  (e: 'setPreviousSlide'): void
}>()

const setNextSlide = () => {
  emit('setNextSlide')
}

const setPreviousSlide = () => {
  emit('setPreviousSlide')
}
const slideCounter = computed(() => {
  return `${props.currentSlide + 1}/${props.countSlides}`
})
</script>

<style lang="scss" scoped>
.carousel-navigation {
  display: flex;

  &__counter {
    @include paragraph-m;

    margin: 0;
    align-self: center;
    margin-right: 16px;
    font-weight: var(--font-weight-bold);
  }

  &__button {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.12s ease;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--cobalt-700);
      }
    }

    &--prev {
      background: var(--cobalt-600) url('@/assets/icons/16-chevron-left-white.svg')
        no-repeat 22px 22px;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-image: url('@/assets/icons/16-chevron-left-white.svg');
        }
      }

      &.carousel-navigation__button--disabled {
        pointer-events: none;
        cursor: default;
        background-image: url('@/assets/icons/16-chevron-left-white.svg');
        background-color: var(--paper-500);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            cursor: default;
            background-color: var(--paper-500);
            background-image: url('@/assets/icons/16-chevron-left-white.svg');
          }
        }
      }
    }

    &--next {
      margin-left: 16px;
      background: var(--cobalt-600) url('@/assets/icons/16-chevron-right-white.svg')
        no-repeat 22px 22px;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-image: url('@/assets/icons/16-chevron-right-white.svg');
        }
      }

      &.carousel-navigation__button--disabled {
        pointer-events: none;
        cursor: default;
        background-image: url('@/assets/icons/16-chevron-right-white.svg');
        background-color: var(--paper-500);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            cursor: default;
            background-image: url('@/assets/icons/16-chevron-right-white.svg');
            background-color: var(--paper-500);
          }
        }
      }
    }
  }
}
</style>
