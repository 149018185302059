<template>
  <div ref="carouselComponent" :class="['carousel', hideNavigationClass]">
    <CarouselNavigation
      :class="['carousel__nav', `carousel__nav--${blok.navigation_alignment}`]"
      :is-first-slide-active="isFirstSlideActive"
      :is-last-slide-active="isLastSlideActive"
      :count-slides="slidesLength"
      :current-slide="currentSlide"
      :is-counter-visible="blok.show_slide_counter"
      @set-previous-slide="setPreviousSlide"
      @set-next-slide="setNextSlide"
    />
    <div
      :id="blok._uid"
      ref="carousel"
      :style="contentStyle"
      class="carousel__content"
    >
      <StoryblokComponent
        v-for="childBlok in blok.items"
        :key="childBlok._uid"
        :blok="childBlok"
        @swipe-left="setNextSlide"
        @swipe-right="setPreviousSlide"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getComputedStyle, checkIfElementIsInViewport } from '@/utils/helpers'
import type { SbCarouselStoryblok } from '@/types'
interface Props {
  blok: SbCarouselStoryblok
}
type Slide = {
  width: number
  marginRight: number
}
const carousel = ref<Element>()
const carouselComponent = ref<Element>()
const props = defineProps<Props>()

const slides = ref<Slide[]>([])
const currentSlide = ref(0)
const slidesOffset = ref(0)
const remainingSlidesWidth = ref(0)
const containerWidth = ref(0)
const keyboardNavEnabled = ref(true)

const updateSlideNodes = () => {
  if (!carousel.value || !carousel.value?.children) {
    return
  }

  const slideNodes = Object.values((carousel.value as Element).children)

  slides.value = slideNodes.map((slide) => ({
    width: getComputedStyle('width', slide),
    marginRight: getComputedStyle('margin-right', slide),
  }))

  if (slides.value.length > 0) {
    remainingSlidesWidth.value = slides.value
      .slice(currentSlide.value)
      .reduce((total, slide) => {
        return total + slide.width + slide?.marginRight
      }, 0)

    remainingSlidesWidth.value -=
      slides.value[slides.value.length - 1].marginRight
  }
}

const updateSlidesOffset = () => {
  const offset = slides.value.reduce(
    (totalOffset: number, slide: Slide, index: number) => {
      if (index < currentSlide.value) {
        return totalOffset + slide.width + slide.marginRight
      }
      return totalOffset
    },
    0,
  )

  slidesOffset.value = offset
}

const updateCarouselElementWidth = () => {
  if (!carouselComponent.value) {
    return
  }

  containerWidth.value = getComputedStyle(
    'width',
    carouselComponent.value as Element,
  )
}

const hideNavigationClass = computed(
  () => props.blok.hide_nav_on_desktop && 'carousel--hidden-navigation',
)

const slidesLength = computed(() => {
  return props.blok && props.blok.items ? props.blok.items.length : 0
})

const allElementsAreVisible = computed(() => {
  return remainingSlidesWidth.value <= containerWidth.value
})

const setSlide = (targetSlide: number) => {
  if (
    targetSlide < 0 ||
    targetSlide >= slidesLength.value ||
    targetSlide === currentSlide.value
  ) {
    return
  }

  currentSlide.value = targetSlide
}

const setNextSlide = () => {
  setSlide(currentSlide.value + 1)
}

const setPreviousSlide = () => {
  setSlide(currentSlide.value - 1)
}

const contentStyle = computed(() => {
  return {
    '--offset-x': `-${slidesOffset.value}px`,
  }
})

const isFirstSlideActive = computed(() => {
  return currentSlide.value === 0
})

const isLastSlideActive = computed(() => {
  return (
    currentSlide.value === slides.value.length - 1 ||
    allElementsAreVisible.value
  )
})

const updateCarousel = () => {
  updateSlideNodes()
  updateSlidesOffset()
  updateCarouselElementWidth()
}

watch(currentSlide, () => {
  updateCarousel()
})

const onKeydown = (event: KeyboardEvent) => {
  if (keyboardNavEnabled.value) {
    if (event.key === 'ArrowLeft') {
      setPreviousSlide()
    } else if (event.key === 'ArrowRight') {
      setNextSlide()
    }
  }
}

const onScroll = () => {
  if (checkIfElementIsInViewport(carouselComponent.value as HTMLElement)) {
    keyboardNavEnabled.value = true
  } else {
    keyboardNavEnabled.value = false
  }
}

onMounted(() => {
  window.addEventListener('resize', updateCarousel)
  window.addEventListener('load', updateCarousel)
  window.addEventListener('keydown', onKeydown)
  window.addEventListener('scroll', onScroll)

  // https://github.com/nuxt/nuxt/issues/8879
  setTimeout(() => {
    updateCarousel()
    onScroll()
  }, 500)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateCarousel)
  window.removeEventListener('load', updateCarousel)
  window.removeEventListener('keydown', onKeydown)
  window.removeEventListener('scroll', onScroll)
  updateCarousel()
})
</script>

<style lang="scss" scoped>
.carousel {
  --offset-x: 0;

  $self: &;

  touch-action: pan-y;

  &--hidden-navigation {
    #{$self}__nav {
      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }

    #{$self}__content {
      @media (min-width: $breakpoint-lg) {
        transition: none;
        transform: translateX(0);
      }
    }
  }

  &__content {
    display: flex;
    align-items: stretch;
    will-change: transition;
    transform: translateX(var(--offset-x));
    transition: 0.4s;
  }

  &__nav {
    display: flex;
    margin-bottom: 32px;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }
}
</style>
